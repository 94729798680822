import { Parent } from '@core/models/entities/parent.models';
import { UpdateFamilyFormValue } from '@features/families/models/family-update.models';
import {
  transformToChildPayload,
  transformToKidFormValue,
  transformToParentFormValue,
} from '@shared/modules/family-forms';
import { Child } from '@core/models/entities/child.models';
import { KidFormValue } from '@shared/modules/family-forms/models/kid-form.models';

export class FamilyUtils {
  static mapParentToFamilyUpdateForm(parent: Parent): UpdateFamilyFormValue {
    const { co_parent, children = [] } = parent as Parent;

    return <UpdateFamilyFormValue>{
      primary: transformToParentFormValue(parent),
      secondary: co_parent ? transformToParentFormValue(co_parent) : null,
      children: children.map((child: Child) => transformToKidFormValue(child)),
    };
  }

  static mapFamilyUpdateToPayload(data: UpdateFamilyFormValue) {
    return {
      ...data,
      isDraft: false,
      children: data.children.map((child: KidFormValue) => transformToChildPayload(child)),
    };
  }
}
