import { HttpErrorResponse } from '@angular/common/http';
import { Action, MemoizedSelector, Store } from '@ngrx/store';
import { catchError, first, map, Observable, of, switchMap, take } from 'rxjs';
import { QueryParams } from '@wlcm/angular/core';

import { LoadPaginatedDataAction } from './store.models';
import { ActionsGroup } from '@store/utils';

export class EffectsBase<S> {
  constructor(public store?: Store) {}

  handleResponse(actions: ActionsGroup): <T>(source: Observable<T>) => Observable<Action> {
    return <T>(source: Observable<T>): Observable<Action> => {
      return source
        .pipe(map((response: T) => <Action>actions.success({ response })))
        .pipe(catchError(({ error }: HttpErrorResponse) => of(<Action>actions.failure({ error }))));
    };
  }

  pullQueryParams(
    selector: MemoizedSelector<S, QueryParams>
  ): (source: Observable<unknown>) => Observable<QueryParams> {
    if (!(this.store instanceof Store)) {
      throw new Error('Store instance in not provided.');
    }

    return (source: Observable<unknown>): Observable<QueryParams> => {
      return source.pipe(switchMap(() => (this.store as Store<S>).select(selector).pipe(take(1))));
    };
  }

  mapQueryParams<T>(selector: MemoizedSelector<S, T>): (source: Observable<LoadPaginatedDataAction>) => Observable<T> {
    return (source: Observable<LoadPaginatedDataAction>): Observable<T> => {
      if (!(this.store instanceof Store)) {
        throw new Error('Store instance in not provided.');
      }

      return source.pipe(
        switchMap(({ payload: params }: LoadPaginatedDataAction) =>
          (this.store as Store<S>)
            .select(selector)
            .pipe(map((searchParams: T) => ({ ...searchParams, ...(params || {}) })))
            .pipe(take(1))
        )
      );
    };
  }

  getEntityByIndex<T>(index: number, selector: MemoizedSelector<S, T[]>): Observable<T> {
    if (!(this.store instanceof Store)) {
      throw new Error('Store instance in not provided.');
    }

    return (this.store as Store<S>).select(selector).pipe(
      map((entities: T[]) => entities[index]),
      first()
    );
  }
}
