import { ActionCreator, emptyProps, props } from '@ngrx/store';
import { ApiError } from '@core/models/http.models';

/* eslint-disable @typescript-eslint/no-explicit-any */

export interface ActionsGroup {
  init: ActionCreator;
  success: ActionCreator;
  failure: ActionCreator;
}

export class ActionsUtils {
  static makeEvents<R>() {
    return {
      init: emptyProps(),
      success: props<{ response: R }>(),
      failure: props<{ error: ApiError }>(),
    };
  }
  static makeEventsWithPayload<P, R>() {
    return {
      init: props<{ payload: P }>(),
      store: props<{ payload: P }>(),
      success: props<{ response: R }>(),
      failure: props<{ error: ApiError }>(),
    };
  }
}
