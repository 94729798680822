import { on } from '@ngrx/store';
import {
  EntityStateBase,
  FailureAction,
  LoadPaginatedDataAction,
  LoadPaginatedDataSuccessAction,
  PaginatedState,
} from '@store/models';
import { ActionCreator } from '@ngrx/store/src/models';
import { EntityAdapter } from '@ngrx/entity';

interface OnConfig<S> {
  keys: (keyof S)[];
}

export class ReducerUtils {
  static makeOnEnd<S = EntityStateBase>(action: ActionCreator, config?: OnConfig<S>) {
    const { keys = ['isProcessing'] } = config || {};
    const endState = keys.reduce((acc, key) => ({ ...acc, [key]: false }), {});
    return on(action, (state: S): S => ({ ...state, ...endState }));
  }

  static handleLoadInit<S extends EntityStateBase>(): (state: S) => S {
    return (state: S): S => {
      return { ...state, isFetching: true };
    };
  }

  static handleProcessInit<S extends EntityStateBase>(): (state: S) => S {
    return (state: S): S => {
      return { ...state, isProcessing: true };
    };
  }

  static handleLoadFailure<S extends EntityStateBase>(): (state: S, action: FailureAction) => S {
    return (state: S): S => {
      return { ...state, isFetching: false };
    };
  }

  static handleProcessFailure<S extends EntityStateBase>(): (state: S, action: FailureAction) => S {
    return (state: S): S => {
      return { ...state, isProcessing: false };
    };
  }
}

export class ReducerPaginatedUtils {
  static handleLoad<T, S extends PaginatedState<T>>(
    adapter: EntityAdapter<T>
  ): (state: S, action: LoadPaginatedDataAction) => S {
    return (state: S, action: LoadPaginatedDataAction): S => {
      return adapter.removeAll({
        ...state,
        queryParams: { ...state.queryParams, ...action.payload },
        isFetchingPaginated: true,
        isLoaded: false,
      });
    };
  }

  static handleLoadSuccess<T, S extends PaginatedState<T>>(
    adapter: EntityAdapter<T>
  ): (state: S, action: LoadPaginatedDataSuccessAction<T>) => S {
    return (state: S, action: LoadPaginatedDataSuccessAction<T>): S => {
      return adapter.setAll(action.response.data, {
        ...state,
        isLoaded: true,
        isFetchingPaginated: false,
        paginatedData: action.response,
      });
    };
  }

  static handleLoadMore<T, S extends PaginatedState<T>>(): (state: S) => S {
    return (state: S): S => ({
      ...state,
      queryParams: { ...state.queryParams, page: state.queryParams.page + 1 },
      isFetchingPaginated: true,
    });
  }

  static handleLoadMoreSuccess<T, S extends PaginatedState<T>>(
    adapter: EntityAdapter<T>
  ): (state: S, action: LoadPaginatedDataSuccessAction<T>) => S {
    return (state: S, action: LoadPaginatedDataSuccessAction<T>): S => {
      return adapter.addMany(action.response.data, {
        ...state,
        isLoaded: true,
        isFetchingPaginated: false,
        paginatedData: action.response,
      });
    };
  }

  static handleFailure<T, S extends PaginatedState<T>>(
    adapter: EntityAdapter<T>
  ): (state: S, action: FailureAction) => S {
    return (state: S): S => {
      return adapter.removeAll({
        ...state,
        isFetchingPaginated: false,
      });
    };
  }
}
